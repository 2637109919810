/**
 * Story: 7180257105
 */

import {
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
} from '@chiroup/core';
import {
  doesItSmellLikeMedicare,
  TransactionMagicAction,
  TransactionMagicActionType,
  TransactionMagiceResponseType,
} from './transactionMagic';

export const applicableCodes: { [key: string]: boolean } = {
  '98940': true,
  '98941': true,
  '98942': true,
};

/**
 * This examines the transaction and updates it in place.
 *
 * @param transaction
 * @returns
 */
export const atAutoPopulateModifier = (
  transaction: PatientTransaction,
): TransactionMagiceResponseType => {
  let touched = false;
  const youSmellMedicare = doesItSmellLikeMedicare(transaction),
    actions: TransactionMagicActionType[] = [],
    items = (transaction.items || []).filter(
      (item: PatientTransactionItemType) => applicableCodes[item.code ?? ''],
    );

  if (items?.length) {
    if (youSmellMedicare) {
      const addedTo: string[] = [];
      items.forEach((item: PatientTransactionItemType) => {
        let addedModifier = false;
        const itemAsAny = item as any,
          modifiers = [
            item.modifier1,
            item.modifier2,
            item.modifier3,
            item.modifier4,
          ];

        let idx = 1;
        while (modifiers.length && !addedModifier) {
          const modifier = modifiers.shift();
          if (!modifier) {
            itemAsAny[`modifier${idx}`] = 'AT';
            addedModifier = true;
            touched = true;
            addedTo.push(item.code ?? '-unknown-');
            touched = true;
          } else if (modifier === 'AT') {
            addedModifier = true;
          }
          idx++;
        }
        if (!addedModifier) {
          actions.push({
            message: `All modifiers already populated for ${item.code}.`,
            type: TransactionMagicAction.Noop,
          });
        }
      });
      if (addedTo.length) {
        actions.push({
          message: `Added modifier AT to code${
            addedTo.length === 1 ? '' : 's'
          } ${addedTo.join(', ')}.`,
          type: TransactionMagicAction.Info,
        });
      }
      if (touched) {
        transaction.services = transaction.items.filter((item) =>
          isaServiceItem(item.subtype),
        );
      }
    } else {
      const codes: string[] = [];

      items.forEach((item: PatientTransactionItemType) => {
        let foundModifier = false;
        const modifiers = [
          item.modifier1,
          item.modifier2,
          item.modifier3,
          item.modifier4,
        ];

        while (modifiers.length && !foundModifier) {
          const modifier = modifiers.shift();
          if (modifier === 'AT') {
            foundModifier = true;
            codes.push(item.code ?? '-unknown-');
          }
        }
      });
      if (codes.length) {
        actions.push({
          message: `Found code${codes.length === 1 ? '' : 's'} ${codes.join(
            ', ',
          )} with modifier AT. This may not be appropriate without Medicare on the transaction.`,
          type: TransactionMagicAction.Warning,
        });
      }
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};

import { Loading, Maintenance } from '@chiroup/components';
import dayjs from 'dayjs';
import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import useMe from '../../hooks/useMe';
import LoadingPage from '../common/LoadingPage';
import BillingBanner from './BillingBanner';
import { v4 } from 'uuid';
import WebsocketContext from '../../contexts/websocket.context';
import EHRTermsOfUseAcceptance from '../EHRTermsOfUseAcceptance';

const NoClinicSelected = lazy(() => import('../NoClinicSelected'));
const ClinicSelectedContainer = lazy(() => import('./ClinicSelectedContainer'));

type Props = {
  children: any;
};

const Container: React.FC<Props> = ({ children }) => {
  const sessionId = useMemo(() => v4(), []);
  const { pathname, state }: any = useLocation();
  const {
    me,
    updateMe,
    unselectClinic,
    selectClinic,
    selectLocation,
    hasAccess,
    activeLocations,
    selectedLocationFull,
    hasRole,
    downForMaintenance,
  } = useMe(state?.qsPlan);
  const navigate = useNavigate();
  // const [modalState, setModalState] = useState<OpenClosedStates>(
  //   OpenClosedStates.Closed
  // );

  useEffect(() => {
    if (me?.selectedClinic) {
      const pastDue = me?.selectedClinic?.subscription?.status === 'past_due';
      const active = me?.selectedClinic?.subscription?.status === 'active';
      const trialing = me?.selectedClinic?.subscription?.status === 'trialing';
      const pastDueDays = me?.selectedClinic?.subscription?.pastDueDays;
      const billDateHasNotPassed =
        dayjs(me?.selectedClinic?.billDate) > dayjs();
      const requiresAction =
        (me?.selectedClinic?.subscription?.status === 'requires_action' ||
          me?.selectedClinic?.subscription?.status === 'incomplete') &&
        billDateHasNotPassed;
      const pastDueUnder7Days =
        pastDue && typeof pastDueDays === 'number' && pastDueDays < 7;
      const blockEverything =
        !active && !trialing && !pastDueUnder7Days && !requiresAction;

      if (blockEverything) {
        navigate('/settings/subscription');
      }
    }
  }, [me?.selectedClinic, navigate, pathname]);

  // TODO: FYI if we add this back, it is asking users who also have clinics that aren't education clinics to upload their student ID - it should only do this for education clinics
  // useEffect(() => {
  //   if (me) {
  //     if (me.studentRecord) {
  //       if (
  //         !me.studentRecord.studentId?.length &&
  //         dayjs().diff(dayjs(me?.createDate), 'hours') >= 48
  //       ) {
  //         if (pathname !== '/') {
  //           navigate('/');
  //         }
  //         setModalState(OpenClosedStates.Open);
  //       }
  //     }
  //   }
  // }, [history, me, pathname]);

  // const close = () => {
  //   getMe();
  //   setModalState(OpenClosedStates.Closed);
  // };

  return me ? (
    <Suspense fallback={<Loading />}>
      <MeContext.Provider
        value={{
          me,
          updateMe,
          unselectClinic,
          selectClinic,
          selectLocation,
          hasAccess,
          activeLocations,
          selectedLocationFull,
          hasRole,
          sessionId,
        }}
      >
        <WebsocketContext>
          {me.selectedClinic ? (
            <ClinicSelectedContainer>
              <BillingBanner />
              <EHRTermsOfUseAcceptance
                isOpen={
                  me?.selectedClinic &&
                  hasAccess('ehr') &&
                  !me?.hasAcceptedEHRTerms
                }
              />
              {children}
            </ClinicSelectedContainer>
          ) : (
            <NoClinicSelected clinics={me.clinics} select={selectClinic} />
          )}
        </WebsocketContext>
      </MeContext.Provider>
    </Suspense>
  ) : downForMaintenance ? (
    <Maintenance
      title={downForMaintenance.title}
      message={downForMaintenance.message}
    />
  ) : (
    <LoadingPage />
  );
};

export default Container;

import { PatientTransaction } from '@chiroup/core';
import { atAutoPopulateModifier } from './autoPopulateMedicareModifier';

export enum TransactionMagicEvent {
  onChangeService = 'onChangeService',
  onChangeInsurance = 'onChangeInsurance',
}

export enum TransactionMagicAction {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Updated = 'updated',
  Noop = 'noop', // No operations performed.
}

export type TransactionMagicActionType = {
  message: string;
  type?: TransactionMagicAction;
};

export type TransactionMagiceResponseType = {
  transaction: PatientTransaction;
  actions: TransactionMagicActionType[];
  touched: boolean;
};

const transactionSpells = {
  onChangeService: [atAutoPopulateModifier],
  onChangeInsurance: [atAutoPopulateModifier],
};

export const transactionMagic = ({
  transaction,
  event,
  onEntry = null,
  onExit = null,
  trace = false,
}: {
  transaction: PatientTransaction | undefined | null;
  event: TransactionMagicEvent;
  onEntry?: ((transaction: PatientTransaction) => void) | null;
  onExit?: ((transaction: PatientTransaction) => void) | null;
  trace?: boolean;
}) => {
  if (!transaction) {
    return {
      actions: [
        {
          message: `No actions possible without a transaction.`,
          type: TransactionMagicAction.Error,
        },
      ],
      transaction: null,
    };
  }
  const actions: TransactionMagicActionType[] = [],
    magicResponses: {
      [key: string]: TransactionMagiceResponseType[];
    } = {};

  onEntry?.(transaction);
  if (trace) {
    console.log({ transaction, event });
  }

  if (transactionSpells[event]) {
    magicResponses[event] = magicResponses[event] || [];
    transactionSpells[event].forEach((magic) => {
      try {
        magicResponses[event].push(magic(transaction));
      } catch (e: any) {
        console.error(e);
        actions.push({
          message: `Error: ${e.message}`,
          type: TransactionMagicAction.Error,
        });
      }
    });
  } else {
    actions.push({
      message: 'No available spells for this event.',
      type: TransactionMagicAction.Warning,
    });
  }

  let touched = false;
  Object.keys(magicResponses).forEach((key) => {
    magicResponses[key].forEach((response) => {
      if (response.touched) {
        touched = true;
      }
      actions.push(...response.actions);
    });
  });
  onExit?.(transaction);
  console.log(actions);
  return { actions, transaction, touched };
};

// Are any of the insurances Medicare or Medicaid?
export const doesItSmellLikeMedicare = (transaction: PatientTransaction) => {
  return transaction?.insurances?.some(
    (i: any) =>
      (i?.insuranceProgram ?? '').indexOf('mcare') === 0 ||
      (i?.insuranceProgram ?? '').indexOf('mcaid') === 0 ||
      (i?.insuranceProgram ?? '').indexOf('hmomedicare') === 0,
  );
};

import { Button } from '@chiroup/components';
import {
  AppointmentInsuranceType,
  ChiroUpJSON,
  DisciplineInsuranceBenefit,
  FormError,
  PatientTransactionItemType,
} from '@chiroup/core';
import { useCallback, useState } from 'react';
import Accordion from '../../../../../../components/common/Accordion';
import { InsuranceError } from '../services/CodeEncounterDiagnosisServices';
import EncounterInsuranceFormFields from './EncounterInsuranceFormFields';
import EncounterInsuranceService from './EncounterInsuranceService';

export const getBillingPriorityError = (
  index: number,
  insuranceErrors: InsuranceError[],
): FormError | undefined => {
  const error = insuranceErrors.find(
    (err) => err.index === index && err.field === 'billingPriority',
  );

  if (error) {
    return { message: error.message };
  }

  return undefined;
};

type Props = {
  insurances: Partial<AppointmentInsuranceType>[];
  readOnly: boolean;
  appointmentInsuranceIndex: number;
  appointmentInsurance: Partial<AppointmentInsuranceType>;
  setInsurances: (ins: Partial<AppointmentInsuranceType>[]) => void;
  services: PatientTransactionItemType[];
  insuranceErrors: InsuranceError[];
  disciplineBenefit?: DisciplineInsuranceBenefit | null;
  onRemoveInsurance: (insurance: Partial<AppointmentInsuranceType>) => void;
  allowBillingPriorityChange: boolean;
};

const EncounterInsurancePolicy: React.FC<Props> = ({
  insurances,
  readOnly,
  setInsurances,
  appointmentInsuranceIndex,
  appointmentInsurance,
  services,
  insuranceErrors,
  disciplineBenefit,
  onRemoveInsurance,
  allowBillingPriorityChange = false,
}) => {
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [serviceAccordions, setServiceAccordions] = useState<number[]>([]);

  const handleAllowedAmountChange = useCallback(
    (amount: number, service: PatientTransactionItemType) => {
      const serviceCode = service?.code as string;

      const prev = ChiroUpJSON.clone(insurances);

      const foundInsuranceIndex = prev.findIndex(
        (ins: Partial<AppointmentInsuranceType>) =>
          ins.insuranceID === appointmentInsurance?.insuranceID,
      );

      const newAllowedAmounts: any =
        prev[foundInsuranceIndex].serviceAllowedAmounts || {};
      newAllowedAmounts[serviceCode] = newAllowedAmounts[serviceCode] || {};
      newAllowedAmounts[serviceCode].allowedAmount = amount;

      prev[foundInsuranceIndex].serviceAllowedAmounts = newAllowedAmounts;

      setInsurances(prev);
    },
    [appointmentInsurance?.insuranceID, insurances, setInsurances],
  );

  const getAllowedAmount = (
    appointmentInsurance: Partial<AppointmentInsuranceType>,
    service: any,
    serviceCode: string,
  ) => {
    let allowedAmount =
      appointmentInsurance?.serviceAllowedAmounts?.[serviceCode]?.allowedAmount;
    if (allowedAmount === undefined) {
      const payor = service?.payors?.find(
        (p: any) => p.payorIntPk === appointmentInsurance?.payorID,
      );
      if (payor) {
        allowedAmount = payor.allowedAmount;
      }
    }

    return allowedAmount;
  };

  const getSelectedInsuranceServiceAmount = useCallback(
    (
      service: PatientTransactionItemType,
      appointmentInsurance: Partial<AppointmentInsuranceType>,
    ) => {
      const serviceCode = service?.code as string;
      //The getAllowedAmount function was created to handle the cas where
      // an insurance exists in the claim modal before plan is imported
      const allowedAmount = getAllowedAmount(
        appointmentInsurance,
        service,
        serviceCode,
      );
      const serviceAllowedAmount =
        allowedAmount !== undefined ? Number(allowedAmount) : undefined;
      const serviceBilledAmount = Number(service?.amount || 0);
      if (typeof serviceAllowedAmount === 'number') {
        return serviceAllowedAmount;
      } else {
        handleAllowedAmountChange(serviceBilledAmount || 0, service);
        return service?.amount ? serviceBilledAmount : 0;
      }
    },
    [handleAllowedAmountChange],
  );

  const toggleAccordion = (id: string) => {
    setOpenAccordions((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const toggleServiceAccordion = (index: number) => {
    setServiceAccordions((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index],
    );
  };

  console.log({ insurances, appointmentInsuranceIndex, disciplineBenefit });

  return (
    <Accordion
      key={
        appointmentInsurance?.insuranceID ||
        `policy-${appointmentInsuranceIndex}`
      }
      title={
        <p className="flex items-center text-sm">
          <span>{appointmentInsurance?.insuranceName || ''}</span>
          <span
            className={
              appointmentInsurance?.inNetwork
                ? 'rounded-lg bg-primary-500 py-1 px-2 text-[10px] leading-[10px] font-light text-white ml-2'
                : ''
            }
          >
            {appointmentInsurance?.inNetwork ? 'In Network' : ''}
          </span>
        </p>
      }
      subtitle={
        getBillingPriorityError(appointmentInsuranceIndex, insuranceErrors)
          ?.message &&
        !openAccordions.includes(appointmentInsurance?.insuranceID || '') ? (
          <span className="text-red-500">
            {
              getBillingPriorityError(
                appointmentInsuranceIndex,
                insuranceErrors,
              )?.message
            }
          </span>
        ) : (
          ''
        )
      }
      open={openAccordions.includes(appointmentInsurance?.insuranceID || '')}
      onToggle={() => toggleAccordion(appointmentInsurance?.insuranceID || '')}
      hugSides
      hugTop
      border
    >
      <form>
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300 mb-6">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            <EncounterInsuranceFormFields
              index={appointmentInsuranceIndex}
              insurances={insurances}
              setInsurances={setInsurances}
              readOnly={readOnly}
              insuranceErrors={insuranceErrors}
              coInsurance={disciplineBenefit?.coInsurance}
              coPay={disciplineBenefit?.coPay}
              allowBillingPriorityChange={allowBillingPriorityChange}
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col gap-1">
        {services?.map((service, index) => (
          <Accordion
            title={
              <p className="truncate overflow-hidden text-sm max-w-sm">
                <span>{`${service.code} - ${service.description}`}</span>
              </p>
            }
            key={index}
            subtitle={
              <p>{`${service.units} Unit${
                +(service?.units ?? 0) > 1 ? 's' : ''
              }`}</p>
            }
            onToggle={() => toggleServiceAccordion(index)}
            open={serviceAccordions.includes(index)}
            border
            hugSides
          >
            <EncounterInsuranceService
              key={index}
              readonly={readOnly}
              service={service}
              onChangeSelectedInsuranceServiceAmount={handleAllowedAmountChange}
              selectedInsuranceServiceAmount={getSelectedInsuranceServiceAmount(
                service,
                appointmentInsurance,
              )}
            />
          </Accordion>
        ))}
      </div>
      {!readOnly && (
        <div className="flex justify-end mt-4">
          <Button
            text="Remove"
            onClick={() => onRemoveInsurance(appointmentInsurance)}
            disabled={readOnly}
          />
        </div>
      )}
    </Accordion>
  );
};
export default EncounterInsurancePolicy;
